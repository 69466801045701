<template>
  <div>
    <query-form class="content_block" label-width="100px" @search="handleSearch" @reset="handleReset">
      <!-- <el-form-item label="办理类型：" prop="type">
        <el-select v-model="form.type">
          <el-option :value="undefined" label="全部">全部</el-option>
          <el-option value="1" label="备件申领"></el-option>
          <el-option value="2" label="备件退回"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="申请来源：" prop="source">
        <el-select v-model="form.source">
          <el-option :value="undefined" label="全部">全部</el-option>
          <el-option v-for="i in StockSourceList" :key="i.value" :value="i.value" :label="i.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-select v-model="form.status">
          <el-option :value="undefined" label="全部">全部</el-option>
          <el-option v-for="(val, key, index) in STATUSOBJ" :key="index" :value="key" :label="val"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备件名称：" prop="name">
        <el-input v-model="form.name" placeholder="请输入备件名称"></el-input>
      </el-form-item>
      <!-- <el-form-item label="发起人：" prop="uesrName">
            <el-input v-model="form.uesrName" placeholder="请输入发起人"></el-input>
          </el-form-item> -->
    </query-form>
    <div class="content_block">
      <el-tabs v-model="form.type" @tab-click="handleTabClick">
        <el-tab-pane label="备件申领" name="1"></el-tab-pane>
        <el-tab-pane label="备件还货" name="2"></el-tab-pane>
      </el-tabs>
      <table-with-pagination
        :tableData="customerList"
        :columnList="columnList"
        :pagination.sync="pagination"
        @current-change="getTableList"
        @sort-change="getTableList"
      />
    </div>
    <el-dialog :title="dealTitle" :visible.sync="dealDialog" width="1200px">
      <el-form :model="dealForm" label-width="120px" ref="dealFormRef">
        <el-row>
          <el-col :span="6">
            <el-form-item label="备件名称：" prop="sparePartName">
              <div>{{ dealForm.sparePartName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="备件编码：" prop="sparePartCode">
              <div>{{ dealForm.sparePartCode }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="备件类型：" prop="sparePartTypeName">
              <div>{{ dealForm.sparePartTypeName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格型号：" prop="spec">
              <div>{{ dealForm.spec }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="数量：" prop="quantity">
              <div>{{ dealForm.quantity }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="原仓库：" prop="oldWarehouseName">
              <div>{{ dealForm.oldWarehouseName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="原仓库库存：" prop="oldQuantity">
              <div>{{ dealForm.oldQuantity }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="申领日期：" prop="createTime">
              <div>{{ dealForm.createTime }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="申领编号：" prop="code">
              <div>{{ dealForm.code }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态：" prop="status">
              <div>{{ STATUSOBJ[+dealForm.status] }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="申领人：" prop="createUserName">
              <div>{{ dealForm.createUserName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="目标仓库：" prop="newWarehouseName">
              <div>{{ dealForm.newWarehouseName }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请备注：" prop="remark">
              <div>{{ dealForm.remark }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col v-if="+dealForm.status === 1" :span="18">
            <el-form-item label="办理意见：" prop="opinion">
              <el-input type="textarea" :maxlength="300" v-model="dealForm.opinion"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="+dealForm.status === 5" :span="18">
            <el-form-item label="审批意见：" prop="approvalOpinion">
              <el-input type="textarea" :maxlength="300" v-model="dealForm.approvalOpinion"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDealHandle">取 消</el-button>
        <el-button v-if="+dealForm.status === 5" type="danger" @click="approvalHandle(2)">驳 回</el-button>
        <el-button v-if="+dealForm.status === 5" type="primary" @click="approvalHandle(1)">通 过</el-button>
        <el-button v-if="+dealForm.status === 1" type="danger" @click="sureDealHandle(2)">拒 绝</el-button>
        <el-button v-if="+dealForm.status === 1" type="primary" @click="sureDealHandle(1)">办 理</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import _ from 'lodash';
import { handleStockInOutList, handleStockInOutApi, approveStockInOutApi } from '@/api/stock.js';
import { dateFormat } from '@/utils/dateFormat.js';
import { STATUSOBJ, OUTINSTOCKTYPE, StockSourceList, StockSourceEnum } from '../stockType.js';

export default {
  name: 'STOCKIN_DEAL',
  mixins: [TablePaginationMixin],
  data () {
    return {
      STATUSOBJ,
      OUTINSTOCKTYPE,
      StockSourceList,
      StockSourceEnum,
      dealForm: {
        opinion: '',
        approvalOpinion: '',
      },
      dealDialog: false,
      dealTitle: '',
      form: {
        source: undefined,
        name: '',
        status: undefined,
        type: '1',
        // uesrName: '',
      },
      customerList: [],
      curRow: {},
    };
  },
  methods: {
    gotoDetail ({ id }) {
      this.$router.push({
        name: 'sparePart_handleStockInAndOut_detail',
        query: {
          id,
        },
      });
    },
    cancelDealHandle () {
      this.dealDialog = false;
      this.dealForm = {};
    },
    async approvalHandle (type) {
      if (type === 2 && !this.dealForm.approvalOpinion) {
        return this.$message.error('请输入驳回意见');
      }
      const param = {
        approvalOpinion: this.dealForm.approvalOpinion,
        id: this.dealForm.id,
        status: type,
      };
      const rs = await approveStockInOutApi(param);
      if (rs.heads.code === 200) {
        this.$message.success('操作成功');
        this.cancelDealHandle();
        this.handleSearch();
      }
    },
    async sureDealHandle (status) {
      if (status === 2 && !this.dealForm.opinion) {
        return this.$message.error('请输入拒绝意见');
      }
      const param = {
        opinion: this.dealForm.opinion,
        id: this.dealForm.id,
        status,
      };
      const rs = await handleStockInOutApi(param);
      if (rs.heads.code === 200) {
        this.$message.success('操作成功');
        this.cancelDealHandle();
        this.handleSearch();
      }
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    handleReset () {
      const type = this.form.type;
      Object.keys(this.form).forEach((i) => {
        this.form[i] = undefined;
      });
      this.form.type = type;
      this.handleSearch();
    },
    handleTabClick () {
      this.pagination.currentPage = 1;
      this.handleSearch();
    },
    async getTableList () {
      const param = {
        ...this.form,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        orderBy: [
          {
            orderByProperty: 'createTime',
            orderByType: 'DESC',
          },
        ],
      };
      const rs = await handleStockInOutList(param);
      if (rs.heads.code === 200) {
        const { list, total } = rs.body;
        this.customerList = list;
        this.pagination.total = total;
      }
    },
    openDealDialog (row) {
      if (+row.status === 1) {
        this.dealTitle = '办理';
      } else if (+row.status === 5) {
        this.dealTitle = '审批';
      }
      this.dealForm = _.cloneDeep(row);
      this.dealForm.createTime
          = this.dealForm.createTime && dateFormat(this.dealForm.createTime).format('YYYY-MM-DD HH:mm:ss');
      this.dealDialog = true;
    },
  },
  computed: {
    columnList () {
      if (this.form.type === '1') {
        return [
          {
            tooltip: true,
            label: '申请编号',
            minWidth: '120px',
            fixed: 'left',
            prop: 'code',
            render: (h, { row }) => {
              return (
                <a class={'text-primary'} style={'cursor: pointer'} onClick={this.gotoDetail.bind(this, row)}>
                  {row.code}
                </a>
              );
            },
          },
          {
            tooltip: true,
            label: '申请日期',
            minWidth: '160px',
            prop: 'createTime',
            format: (val) => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
          },
          {
            tooltip: true,
            label: '申请来源',
            minWidth: '100px',
            prop: 'source',
            format: val=>{ return StockSourceEnum[val]; },
          },
          {
            tooltip: true,
            label: '办理类型',
            minWidth: '100px',
            prop: 'type',
            format: (val) => val && OUTINSTOCKTYPE[val],
          },
          {
            tooltip: true,
            label: '状态',
            minWidth: '180px',
            prop: 'status',
            format: (val) => val && STATUSOBJ[+val],
          },
          {
            tooltip: true,
            label: '备件名称',
            minWidth: '180px',
            prop: 'sparePartName',
          },
          {
            tooltip: true,
            label: '备件编码',
            minWidth: '180px',
            prop: 'sparePartCode',
          },
          {
            tooltip: true,
            label: '备件规格型号',
            minWidth: '180px',
            prop: 'spec',
          },
          {
            tooltip: true,
            label: '数量',
            minWidth: '180px',
            prop: 'quantity',
          },
          {
            tooltip: true,
            label: '目标仓库',
            minWidth: '180px',
            prop: 'newWarehouseName',
          },
          {
            tooltip: true,
            label: '原始仓库',
            minWidth: '180px',
            prop: 'oldWarehouseName',
          },
          {
            label: '操作',
            minWidth: '180px',
            prop: 'actions',
            fixed: 'right',
            render: (h, { row }) => {
              let actions = [];
              if (+row.status === 1) {
                actions.push(
                  <span
                    v-auth={'sparePart:handleStockInAndOut:handle'}
                    class='cursor-pointer text-primary ml-1 '
                    onClick={this.openDealDialog.bind(this, row)}
                  >
                    办理
                  </span>,
                );
              } else if (+row.status === 5) {
                actions.push(
                  <span
                    v-auth={'sparePart:handleStockInAndOut:approval'}
                    class='cursor-pointer text-primary ml-1 '
                    onClick={this.openDealDialog.bind(this, row)}
                  >
                    审批
                  </span>,
                );
              }

              return actions;
            },
          },
        ];
      } else {
        return [
          {
            tooltip: true,
            label: '申请编号',
            minWidth: '120px',
            fixed: 'left',
            prop: 'code',
            render: (h, { row }) => {
              return (
                <a class={'text-primary'} style={'cursor: pointer'} onClick={this.gotoDetail.bind(this, row)}>
                  {row.code}
                </a>
              );
            },
          },
          {
            tooltip: true,
            label: '申请日期',
            minWidth: '160px',
            prop: 'createTime',
            format: (val) => val && dateFormat(val).format('YYYY-MM-DD HH:mm:ss'),
          },
          {
            tooltip: true,
            label: '申请来源',
            minWidth: '100px',
            prop: 'source',
            format: val=>{ return StockSourceEnum[val]; },
          },
          {
            tooltip: true,
            label: '办理类型',
            minWidth: '100px',
            prop: 'type',
            format: (val) => val && OUTINSTOCKTYPE[val],
          },
          {
            tooltip: true,
            label: '状态',
            minWidth: '180px',
            prop: 'status',
            format: (val) => val && STATUSOBJ[+val],
          },
          {
            tooltip: true,
            label: '备件名称',
            minWidth: '180px',
            prop: 'sparePartName',
          },
          {
            tooltip: true,
            label: '备件编码',
            minWidth: '180px',
            prop: 'sparePartCode',
          },
          {
            tooltip: true,
            label: '备件规格型号',
            minWidth: '180px',
            prop: 'spec',
          },
          {
            tooltip: true,
            label: '还货数量',
            minWidth: '180px',
            prop: 'quantity',
          },
          {
            tooltip: true,
            label: '目标仓库',
            minWidth: '180px',
            prop: 'newWarehouseName',
          },
          {
            tooltip: true,
            label: '原始仓库',
            minWidth: '180px',
            prop: 'oldWarehouseName',
          },
          {
            label: '操作',
            minWidth: '180px',
            prop: 'actions',
            fixed: 'right',
            render: (h, { row }) => {
              let actions = [];
              if (+row.status === 1) {
                actions.push(
                  <span
                    v-auth={'sparePart:handleStockInAndOut:handle'}
                    class='cursor-pointer text-primary ml-1 '
                    onClick={this.openDealDialog.bind(this, row)}
                  >
                    办理
                  </span>,
                );
              } else if (+row.status === 5) {
                actions.push(
                  <span
                    v-auth={'sparePart:handleStockInAndOut:approval'}
                    class='cursor-pointer text-primary ml-1 '
                    onClick={this.openDealDialog.bind(this, row)}
                  >
                    审批
                  </span>,
                );
              }

              return actions;
            },
          },
        ];
      }
    },
  },
};
</script>
